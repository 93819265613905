import { camelCaseify, snakeCaseify } from "@/utils/with-case"

export const digest = async (text: string, algorithm: AlgorithmIdentifier = "SHA-1"): Promise<string> => {
  const bytes = new TextEncoder().encode(text)
  const digest = await crypto.subtle.digest(algorithm, bytes)

  return new TextDecoder().decode(digest)
}

const util = {
  copy: (text: string): boolean => {
    let input = document.createElement("textarea")
    input.innerHTML = text
    document.body.appendChild(input)
    input.select()
    const result = document.execCommand("copy")
    document.body.removeChild(input)
    return result
  },
  clone: (obj: Object): Object => {
    return JSON.parse(JSON.stringify(obj))
  },
  sleep: ms => new Promise(resolve => setTimeout(resolve, ms)),
  searchParam: (param: string): string => {
    const url = location.href
    const urlObj = new URL(url)
    return urlObj.searchParams.get(param) || ""
  },
  objWithCamelCaseComp: (obj: any): any => ({ ...camelCaseify(obj), ...obj }),
  objWithSnakeCaseComp: (obj: any): any => ({ ...snakeCaseify(obj), ...obj }),
  snakeCaseify,
  camelCaseify,
  digest,
}

export default util
